import React, { useState, useEffect } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import "./nav.scss";
import Img from "gatsby-image";
import { motion } from "framer-motion";
import { isMobile } from "react-device-detect";

export default () => {
  const [ham, changeHam] = useState(isMobile ? false : true);
  const [subMenu, changeSubMenu] = useState(true);
  const data = useStaticQuery(graphql`
    query navlogo {
      file(relativePath: { eq: "components/logo.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  const resetHam = () => {
    if (isMobile) {
      changeHam(!ham);
    }
  };
  let navlink = {
    hidden: {
      opacity: 0,
      y: "-100vh",
    },
    show: {
      opacity: 1,
      y: "0",
    },
  };



  const navbar = {
    hidden: {
      rotateY: 112,
    },
    show: {
      rotateY: 0,
    },
  };

  useEffect(() => {
    if (isMobile) {
      setTimeout(() => {
        changeHam(true);
        changeHam(false);
      }, 1000);
    }
  }, []);
  return (
    <nav>
      <div className="brand">
        <div className="logo">
          <Link to="/">
            <Img fluid={data.file.childImageSharp.fluid} />
          </Link>
        </div>
      </div>
      <div
        className={ham ? "ham ham-open" : "ham"}
        onClick={() => changeHam(!ham)}
      >
        <motion.span
          animate={{ opacity: 1, x: 0, y: 0, color: "white" }}
          initial="false"
        />
        <span />
        <span />
        <span />
        <span />
        <span />
      </div>
      <motion.div
        animate={ham ? "show" : "hidden"}
        initial={ham ? "hidden" : "show"}
        variants={navbar}
        transition={{ duration: 0.1, ease: "easeInOut" }}
        className="navbar"
      >
        <div className="navlinks">
          <motion.div
            animate={ham ? "show" : "hidden"}
            initial={ham ? "hidden" : "show"}
            variants={navlink}
            transition={{ delay: 0.5 }}
            className="navlink"
            onClick={() => resetHam()}
          >
            <span />
            <Link to="/">HOME</Link>
          </motion.div>
          <motion.div
            animate={ham ? "show" : "hidden"}
            initial={ham ? "hidden" : "show"}
            variants={navlink}
            transition={{ delay: 0.6 }}
            className="navlink"
            onClick={() => resetHam()}
          >
            <span />
            <Link to="/about-us">ABOUT</Link>
          </motion.div>
          <motion.div
            animate={ham ? "show" : "hidden"}
            initial={ham ? "hidden" : "show"}
            variants={navlink}
            transition={{ delay: 0.7 }}
            className="navlink"
            onClick={() => resetHam()}
          >
            <Link to="/contact-us">CONTACT</Link>
            <span />
          </motion.div>
          <motion.div
            animate={ham ? "show" : "hidden"}
            initial={ham ? "hidden" : "show"}
            variants={navlink}
            transition={{ delay: 0.8 }}
            className="navlink"
            onClick={() => resetHam()}
          >
            <span />
            <Link to="/candidates">CANDIDATES</Link>
          </motion.div>
          <motion.div
            animate={ham ? "show" : "hidden"}
            initial={ham ? "hidden" : "show"}
            variants={navlink}
            transition={{ delay: 0.9 }}
            className="navlink"
            onClick={() => resetHam()}
          >
            <span />
            <Link to="/clients">CLIENTS</Link>
          </motion.div>
          <motion.div
            animate={ham ? "show" : "hidden"}
            initial={ham ? "hidden" : "show"}
            variants={navlink}
            transition={{ delay: 1.0 }}
            className="navlink"
            onClick={() => resetHam()}
          >
            <span />
            <Link to="/services">SERVICES</Link>
          </motion.div>
          <motion.div
            animate={ham ? "show" : "hidden"}
            initial={ham ? "hidden" : "show"}
            variants={navlink}
            transition={{ delay: 1.1 }}
            className="navlink"
            onClick={() => {
              changeSubMenu(!subMenu);
            }}
            onHoverStart={() => {
              changeSubMenu(!subMenu);
            }}
            onHoverEnd={() => {
              changeSubMenu(!subMenu);
            }}
          >
            <span />
            
            
          </motion.div>
        
        </div>
        {/* <ul className="nav-icons">
          <motion.a
            animate={ham ? "show" : "hidden"}
            initial={ham ? "hidden" : "show"}
            variants={navIcon}
            transition={{ delay: 1.2 }}
            href="https://www.facebook.com/"
          >
            <FaFacebookF />
          </motion.a>
          <motion.a
            animate={ham ? "show" : "hidden"}
            initial={ham ? "hidden" : "show"}
            variants={navIcon}
            transition={{ delay: 1.3 }}
            href="https://instagram.com"
          >
            <FaInstagram />
          </motion.a>
          <motion.a
            animate={ham ? "show" : "hidden"}
            initial={ham ? "hidden" : "show"}
            variants={navIcon}
            transition={{ delay: 1.4 }}
            href="https://www.linkedin.com/"
          >
            <FaLinkedinIn />
          </motion.a>
          <motion.a
            animate={ham ? "show" : "hidden"}
            initial={ham ? "hidden" : "show"}
            variants={navIcon}
            transition={{ delay: 1.5 }}
            href="https://twitter.com/"
          >
            <FaTwitter />
          </motion.a>
        </ul> */}
      </motion.div>
    </nav>
  );
};

import { Link, graphql, useStaticQuery } from "gatsby";
import React from "react";

import Img from "gatsby-image";
import "./footer.scss";

export default () => {
  const data = useStaticQuery(graphql`
    query footerlogo {
      file(relativePath: { eq: "components/logo.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return (
    <>
      <footer>
        <div className="footer container">
          <div className="footer-up">
            <div>
              <p>
                71-75 Shelton Street, <br />
                Covent Garden, <br /> London, <br />
                WC2H 9JQ
              </p>
            </div>
            <div className="footer-logo">
              <Link to="/">
                <Img fluid={data.file.childImageSharp.fluid} />
              </Link>
              <a href="mailto:info@premisehealthcare.co.uk">
                info@premisehealthcare.co.uk
              </a>
            </div>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/about-us">About Us</Link>
              </li>
              <li>
                <Link to="/contact-us">Contact Us</Link>
              </li>
              <li>
                <Link to="/candidates">Candidates</Link>
              </li>
              <li>
                <Link to="/clients">Clients</Link>
              </li>
              <li>
                <Link to="/services">Services</Link>
              </li>
            </ul>
          </div>
          <div className="footer-copy">
            {new Date().getFullYear()} Premise Healthcare. All Rights Reserved.
          </div>
        </div>
      </footer>
    </>
  );
};
